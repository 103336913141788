import { SVGProps } from 'react';

const WistiaPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.2 122.88" xmlSpace="preserve" {...props}>
    <path
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      d="M92.2 60.97 0 122.88V0l92.2 60.97z"
    />
  </svg>
);

export default WistiaPlay;
