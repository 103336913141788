import { useEffect, useState } from 'react';

import WistiaFake from 'holded/modules/cms/ui/shared/components/WistiaFake';

type Props = {
  video: string;
  autoPlay?: boolean;
  onClick: () => void;
};

const SCRIPT_URL = 'https://fast.wistia.com/assets/external/E-v1.js';
const INITIAL_THUMB = {
  src: '',
  title: '',
  width: 0,
  height: 0,
};

const InitWistia = (video: string) => {
  const scriptVideoURL = `https://fast.wistia.com/embed/medias/${video}.jsonp`;
  const isScriptVideoLoaded = document.querySelector(`script[src="${scriptVideoURL}"]`);
  const isScriptLoaded = document.querySelector(`script[src="${SCRIPT_URL}"]`);

  if (isScriptVideoLoaded) return;
  const scriptVideo = document.createElement('script');
  scriptVideo.src = scriptVideoURL;
  scriptVideo.async = true;
  document.body.append(scriptVideo);

  if (isScriptLoaded) return;
  const script = document.createElement('script');
  script.src = SCRIPT_URL;
  script.async = true;
  document.body.append(script);
};

const WistiaVideo = ({ video, autoPlay = false, onClick }: Props) => {
  const [thumb, setThumb] = useState(INITIAL_THUMB);

  useEffect(() => {
    if (!autoPlay) {
      //TODO upload a thumbnail to Strapi and not use fetch
      fetch(`https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/${video}`)
        .then((res) => res.json())
        .then((r) => {
          setThumb({
            src: r.thumbnail_url,
            width: r.thumbnail_width,
            height: r.thumbnail_height,
            title: r.title,
          });
        });
    } else {
      InitWistia(video);
    }
  }, []);

  const handleMouseEnter = () => {
    if (autoPlay) return;
    InitWistia(video);
  };

  return (
    <div className="wistia_responsive_padding relative !p-0" onMouseEnter={handleMouseEnter}>
      {!!thumb.src && <WistiaFake thumbnail={thumb} />}
      <div className="wistia_responsive_wrapper h-full w-full absolute top-0 left-0" onClick={onClick}>
        <div
          className={`wistia_embed wistia_async_${video} videoFoam=true autoPlay=${autoPlay} h-full relative w-full`}
        >
          <div className="wistia_swatch absolute h-full left-0 opacity-0 overflow-hidden top-0 w-full">
            <img
              src={`https://fast.wistia.com/embed/medias/${video}/swatch`}
              style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }}
              alt=""
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WistiaVideo;
