import styles from './youtubeVideo.module.scss';

type Props = {
  video: string;
  onClick: () => void;
};

const YoutubeVideo = ({ video, onClick }: Props) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <iframe
        src={`https://www.youtube.com/embed/${video}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default YoutubeVideo;
