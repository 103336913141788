import WistiaPlay from 'holded/modules/cms/ui/shared/icons/WistiaPlay';

type Props = {
  thumbnail: {
    src: string;
    title: string;
    width: number;
    height: number;
  };
};

const WistiaFake = ({ thumbnail: { src, title, width, height } }: Props) => (
  <div className={`relative`}>
    <img src={src} className={`w-full h-full`} width={width} height={height} loading={'lazy'} alt={title} />
    <div
      className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-14 bg-blue-500 opacity-80 hover:opacity-1 h-20 w-32 rounded-sm flex justify-center`}
    />
    <WistiaPlay
      width={26}
      fill={'#fff'}
      className={'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-9 '}
    />
  </div>
);

export default WistiaFake;
