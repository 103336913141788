import { useRouter } from 'next/router';

import { useBoolean } from 'holded/lib/hooks/useBoolean';
import { BASE_URL } from 'holded/modules/cms/domain/page';
import WistiaVideo from 'holded/modules/cms/ui/shared/components/WistiaVideo';
import YoutubeVideo from 'holded/modules/cms/ui/shared/components/YoutubeVideo';

type Props = {
  url: string;
  autoPlay?: boolean;
};

const cleaner = (url: string, clean: string) => {
  return url.slice(0, 1) == clean ? url.slice(1) : '';
};

const Video = ({ url, autoPlay = false }: Props) => {
  const { value: videoStarted, setTrue: setVideoStarted } = useBoolean(false);
  const router = useRouter();
  const currentPath = `${BASE_URL}${router.asPath}`;

  const wistia = url.startsWith('https://holded.wistia.com');
  const video = wistia
    ? cleaner(url.match('\\/[^\\/]*$')?.toString() ?? '', '/')
    : cleaner(url.match('\\=[^\\=]*$')?.toString() ?? '', '=');

  const handlerPlayVideo = () => {
    if (videoStarted) return;
    setVideoStarted();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'video_started', video_id: video, page: currentPath });
  };
  return (
    <>
      {wistia ? (
        <WistiaVideo video={video} autoPlay={autoPlay} onClick={handlerPlayVideo} />
      ) : (
        <YoutubeVideo video={video} onClick={handlerPlayVideo} />
      )}
    </>
  );
};

export default Video;
